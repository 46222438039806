import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';

import Arrow from 'common/icons/arrow-right.svg';
import Pause from 'common/icons/pause-brands.svg';
import Play from 'common/icons/play.svg';
import LinkItem from 'common/LinkItem';

import { HeroBannerBoxProps } from './models';

const HeroBannerBox: FC<HeroBannerBoxProps> = ({
  slide,
  className,
  setIsPausedSlider,
  isPausedSlider,
  numberOfSlides,
  playText,
  pauseText,
}) => {
  const playPauseButtonHandler = () => {
    setIsPausedSlider(!isPausedSlider);
  };

  const PlayPauseButton = useCallback(
    () => (
      <button className="hero-banner__control" type="button" onClick={playPauseButtonHandler}>
        <span className="hero-banner__control-text">{isPausedSlider ? playText : pauseText}</span>
        {isPausedSlider ? <Play aria-hidden="true" /> : <Pause aria-hidden="true" />}
      </button>
    ),
    [isPausedSlider, playPauseButtonHandler]
  );

  const HeroBannerArticleBox = (slideData) => (
    <div className={className}>
      {slideData.articleType?.name ? (
        <div className="hero-banner__type">
          <span className="hero-banner__tag">{slideData.articleType?.name}</span>
        </div>
      ) : null}
      <h3 className="h4 hero-banner__title">{slideData?.title}</h3>
     {/* <div className="hero-banner__buttons">
        {slideData?.url ? (
          <Link className="hero-banner__link" to={slideData?.url}>
            {slideData?.previewLinkTitle || slideData?.title}
            <Arrow className="hero-banner__icon" />
          </Link>
        ) : null}
        {numberOfSlides > 1 ? <PlayPauseButton /> : null}
      </div>*/}
    </div>
  );

  const HeroBannerCustomBox = (slideData) => (
    <div className={className}>
      {slideData.heroBannerArticleType ? (
        <div className="hero-banner__tag-wrap">
          <span className="hero-banner__tag">{slideData.heroBannerArticleType}</span>
        </div>
      ) : null}
      <h3 className="h4 hero-banner__title">{slideData?.title}</h3>
      {/*<div className="hero-banner__buttons">
        {slideData?.cta ? (
          <LinkItem
            title={slideData.cta[0]?.name}
            link={slideData?.cta}
            isLink
            showTitle
            linkClass="hero-banner__link"
            hasIcon
          />
        ) : null}
        {numberOfSlides > 1 ? <PlayPauseButton /> : null}
      </div>*/}
    </div>
  );

  return (
    <>
      {slide.__typename === 'ArticlePagePreviewType'
        ? HeroBannerArticleBox(slide)
        : HeroBannerCustomBox(slide)}
    </>
  );
};

export default HeroBannerBox;
