import React, { FC, useState } from 'react';
import objectHash from 'object-hash';

import CustomCarousel from 'components/CustomCarousel';
import HeroBannerArticle from 'components/HeroBanner/HeroBannerArticle';
import HeroBannerImageSlide from 'components/HeroBanner/HeroBannerImageSlide';

import { HeroBannerProps } from './models';

import './HeroBanner.scss';

const HeroBanner: FC<HeroBannerProps> = ({
  items,
  defaultImage,
  playText,
  pauseText,
  nextSlideText,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide((prevState) => (prevState === items.length - 1 ? 0 : prevState + 1));
  };

  return (
    <section className="hero-banner">
      <CustomCarousel
        autoPlay={false}
        stopOnHover={false}
        infiniteLoop
        showStatus={false}
        showThumbs={false}
        selectedItem={currentSlide}
        swipeable={false}
      >
        {items.map((slide, index) => (
          <HeroBannerImageSlide
            defaultImage={defaultImage}
            slide={slide}
            key={objectHash({ imageSlide: index })}
          />
        ))}
      </CustomCarousel>
      <HeroBannerArticle
        items={items}
        currentSlide={currentSlide}
        nextSlide={next}
        setCurrentSlide={setCurrentSlide}
        playText={playText}
        pauseText={pauseText}
        nextSlideText={nextSlideText}
      /> 
     
    </section>
  );
};
export default HeroBanner;
