import React, { FC } from 'react';
import classNames from 'classnames';

import { Col, Container, Row } from 'layout';
import Image from 'common/Image';
import BannerBase from 'components/BannerBase';
import DownloadLink from 'components/DownloadLink';
import { commonColorHandler } from 'utils/colorsHandler';

import CareersBannerFilters from './CareersBannerFilters';
import { CareersBannerProps } from './models';

import './CareersBanner.scss';

const CareersBanner: FC<CareersBannerProps> = ({
  backgroundImage,
  title,
  summaryText,
  filters,
  backgroundColor,
  buttonText,
  subtitle,
  pdfReportDownload,
}) => (
  <section className="careers-banner">
    <DownloadLink pdfReportDownload={pdfReportDownload} />
    <div className={classNames(`careers-banner__info `)}>
      {backgroundImage && backgroundColor.length < 1 ? (
        <Image
          className="careers-banner__image"
          imageData={backgroundImage}
          alt={backgroundImage?.altText}
        />
      ) : null}

      <Container>
        <Row>
          <Col>
            <div
              className={classNames('careers-banner__intro', {
                'careers-banner__intro--white': backgroundImage && backgroundColor.length < 1,
              })}
            >
              <BannerBase
                bannerClassName="careers-banner"
                subheading={subtitle}
                headlineText={title}
                description={summaryText}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    {filters ? <CareersBannerFilters buttonText={buttonText} filters={filters} /> : null}
  </section>
);

export default CareersBanner;
